var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"lBN3ncEo2XzzTeq9DBvv6"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import { RewriteFrames as RewriteFramesIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

import {
  getMetadataForNetworkCallError,
  getSharedSentryClientConfig,
} from './utils/sentry';

const sharedConfig = getSharedSentryClientConfig();

Sentry.init({
  ...sharedConfig,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  ignoreErrors: [
    // https://stackoverflow.com/a/66260608
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications.',
  ],
  beforeSend(event, hint) {
    const error = hint?.originalException;
    if (axios.isAxiosError(error)) {
      const metadata = getMetadataForNetworkCallError(error);
      event.tags = { ...event.tags, ...metadata.tags };
      event.fingerprint = ['{{ default }}', ...metadata.fingerprints];
      event.message = metadata.message || event.message;
    }
    return event;
  },
  integrations: [
    new RewriteFramesIntegration({
      iteratee: (frame) => {
        frame.filename = frame.filename
          ?.replace('%5B', '[')
          .replace('%5D', ']');
        return frame;
      },
    }),
    // Only needed for SDK versions < 8.0.0
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/metrics/#configure-metrics
    Sentry.metrics.metricsAggregatorIntegration(),
  ],
  // ...
});
